import React, { useContext } from "react"
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import { GatsbyImage } from "gatsby-plugin-image"
import Typography from '@mui/material/Typography'
import useStyles, { BlackSubscription, sxStyle } from './cycleStyle'
import ShareIt from '../../../components/shareComponents'
import getDate from '../../../helpers/dates'

import { AuthContext } from '../../../context/general'


import { navigate } from "gatsby-link"
import { grey, red, indigo } from '@mui/material/colors';







const SectionEvent = ({ data }) => {




    const classes = useStyles()

    if (!data)
        return null

    const pageCycle = data.pageCycle ? data.pageCycle : data.originalPageCycle


    const slug = pageCycle.fields.slug
    const slugSplit = slug.split('/')

    // console.log('@SHARE-IT')
    // console.log(data.site.siteMetadata.url + slug)



    return (<Box sx={sxStyle.sectionEvent}>
        <Box flex={1} display="flex" flexDirection="column">

            <SubAvatar data={data} />
            <SubEvent data={data} />
        </Box>
        <Box flex={1} padding="0 20px 0 20px">
            <SubImage data={data} />
            <Box marginTop='15px' width="100%">
                <ShareIt title={pageCycle.title} url={data.site.siteMetadata.url + slug} />
            </Box>
        </Box>

    </Box>)
}

export default SectionEvent

const SubAvatar = ({ data }) => {
    const classes = useStyles()


    if (!data)
        return null


    const pageCycle = data.pageCycle ? data.pageCycle : data.originalPageCycle

    const formateur = pageCycle.children.find(child => child.nom)

    if (!formateur)
        return null






    let avatarGatsbyImageData = null
    if (formateur?.children.length)
        avatarGatsbyImageData = formateur.children.find(image => image.original?.src.includes('portrait'))?.gatsbyImageData


    return (<Box display="flex" flexDirection="row" >
        <Box style={{ cursor: 'pointer' }} display="flex" flexDirection="row" alignContent="center" justifyContent="center" onClick={() => { navigate(formateur.fields.slug) }}>

            <Avatar style={{ zIndex: 1 }} sx={sxStyle.avatarContainer}>
                <GatsbyImage
                    image={avatarGatsbyImageData}
                    alt={pageCycle.title ? pageCycle.title : ''}
                    loading="lazy"
                    style={{ zIndex: 2 }}

                />
            </Avatar>
        </Box>
        <Box padding="10px" paddingTop="20px">
            <Typography variant="h1" sx={sxStyle.avatarText}>
                {formateur.prenom}{" "} {formateur.nom}
            </Typography>

            <Box display="flex" flexWrap="wrap" flexDirection="rox" alignContent="center" alignItems='center'>

                {formateur.hashList.split('|').map((hash, index) => <Hash key={index} hash={hash} />)}
            </Box>
        </Box>

    </Box>)
}



const Hash = ({ hash }) => {
    return (<Box margin="0 3px 0 3px" padding="5px 12px 5px 12px" borderRadius="15px" marginTop="8px" bgcolor={grey[300]}>
        <Typography variant="h2" style={{ fontSize: '0.6rem' }}>
            {hash}
        </Typography>

    </Box>)
}



const SubEvent = ({ data }) => {

    const pageCycle = data.pageCycle ? data.pageCycle : data.originalPageCycle

    const isTitleShort = pageCycle.title.length < 50



    const isReplayAvailable = pageCycle.replayStatus === 'AVAILABLE' ? true : false
    const buttonMessage = pageCycle.replayStatus === 'AVAILABLE' ? 'Acheter les replays' : "S'inscrire au cycle"
    const linkMessage = pageCycle.replayStatus === 'AVAILABLE' ? 'enregistrées du' : "du"





    const classes = useStyles({ isTitleShort: isTitleShort })
    const { authActions } = useContext(AuthContext)


    const events = pageCycle.children.filter(child => child.cycleId)
    const nbEvents = events.length
    const dates = events.map(event => event.date)
    const firstDate = Math.min(...dates)
    const lastDate = Math.max(...dates)



    const now = new Date()
    const time = parseInt(now.getTime() / 1000)

    const showButton = pageCycle.replayStatus || (time < lastDate + 3600 * 2)

    const imageUrl = `https://www.pekoia.com/static/L${pageCycle?.imageKey}-500-${pageCycle?.imageETag500}.jpg`


    const clickHandler = (isReplayAvailable) => () => {

        const cycleData = {
            title1: pageCycle.title,
            cycleId: pageCycle.fields.cycleId,
            prix: pageCycle.prix,
            cycleImageUrl: imageUrl,
            formateur: pageCycle.children.find(child => child.lemonwayId),
            lemonwayId: pageCycle.children.find(child => child.lemonwayId).lemonwayId,
            isReplayAvailable: isReplayAvailable
        }



        //[TOTO:BACK]
        authActions.toggleSubscriptionModal(cycleData)

    }




    return (<Box sx={sxStyle.subEventContainer}>
        <Box width="100%">
            <Typography variant="h1" sx={sxStyle.eventMainTitle} >
                {pageCycle.title}
            </Typography>
        </Box>

        <Box marginTop="30px" display="flex" flexDirection="row">
            <Typography style={{ fontSize: '0.9rem' }}>
                {nbEvents}{" "}conférence{nbEvents > 1 ? "s" : ""}{" "}{linkMessage}{" "}{getDate(firstDate).date}{" "}au{" "}{getDate(lastDate).date}
            </Typography>

        </Box>
        {isReplayAvailable && <Box>
            <Typography style={{ fontSize: '0.9rem', color: indigo[400], fontWeight: 700 }}>
                disponibles en replay
            </Typography>

        </Box>}



        {showButton && <Box display="flex" flexDirection="row" marginTop="40px">
            <Typography style={{ fontSize: '1.8rem', fontWeight: "bold" }}>
                {pageCycle.prix} €
            </Typography>
            <Typography className={classes.ttc}>
                TTC
            </Typography>
        </Box>}
        {showButton && <Box marginTop="20px" marginBottom='60px'>
            <BlackSubscription onClick={clickHandler(isReplayAvailable)}>
                {buttonMessage}
            </BlackSubscription>

        </Box>}

    </Box>)
}

const SubImage = ({ data }) => {

    const pageCycle = data.pageCycle ? data.pageCycle : data.originalPageCycle

    const classes = useStyles()

    const children = pageCycle.children
        .filter(child => child.gatsbyImageData)
        .filter(child => !child.original.src.includes('800'))
        .filter(child => !child.original.src.includes('500'))
        .filter(child => !child.original.src.includes('300'))
        .filter(child => !child.original.src.includes('blur'))

    let gatsbyImageData = null
    if (pageCycle.children.length) { }
    gatsbyImageData = children.find(child => (child.gatsbyImageData && child.original.src.includes('static/L')))?.gatsbyImageData



    return (
        <Box sx={sxStyle.mainImage}>
            <GatsbyImage
                image={gatsbyImageData}
                alt={pageCycle.title ? pageCycle.title : ''}
                loading="lazy"
                style={{ zIndex: 2, objectFit: 'cover', width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}


            />
        </Box>

    )
}

