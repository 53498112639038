import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button'
import { grey, red } from '@mui/material/colors';
import { styled } from '@mui/material/styles';



export const imageWidth = 280
export const imageHeight = imageWidth * 5 / 4

export const calendarImageWidth = 150
export const calendarImageHeight = calendarImageWidth

export const webinarImageWidth = 220
export const webinarImageHeight = webinarImageWidth * 9 / 16


export const sxStyle = {

  sectionEvent: {
    display: "flex",
    width: "100%",
    flexDirection: {
      xs: 'column', // theme.breakpoints.up('xs')
      sm: 'column', // theme.breakpoints.up('sm')
      md: 'row', // theme.breakpoints.up('md')
      lg: 'row', // theme.breakpoints.up('lg')
      xl: 'row', // theme.breakpoints.up('xl')
    }


  },
  subEventContainer: {
    marginTop: "30px",
    display: "flex",
    width: '100%',
    flexDirection: "column",
    marginTop: {
      xs: '30px', // theme.breakpoints.up('xs')
      sm: '80px', // theme.breakpoints.up('sm')
      md: '80px', // theme.breakpoints.up('md')
      lg: '80px', // theme.breakpoints.up('lg')
      xl: '80px', // theme.breakpoints.up('xl')
    }

  },
  eventMainTitle: {
    fontWeight: 600,
    fontSize: {
      xs: '2.5rem', // theme.breakpoints.up('xs')
      sm: '4.5rem', // theme.breakpoints.up('sm')
      md: '4.5rem', // theme.breakpoints.up('md')
      lg: '4.5rem', // theme.breakpoints.up('lg')
      xl: '4.5rem', // theme.breakpoints.up('xl')
    },
    lineHeight: {
      xs: '3rem', // theme.breakpoints.up('xs')
      sm: '4.3rem', // theme.breakpoints.up('sm')
      md: '4.3rem', // theme.breakpoints.up('md')
      lg: '4.3rem', // theme.breakpoints.up('lg')
      xl: '4.3rem', // theme.breakpoints.up('xl')
    }
  },
  mainImage: {
    width: '700px',
    height: '500px',
    position: 'relative',
    

 
},
avatarContainer: {
  width: "60px",
  height: "60px"
},
avatarText: {
  fontSize: '0.7rem',
  fontWeight: 'bold',
  lineHeight: '0.8rem'
},
}




const useStyles =  makeStyles((theme) => {


  return ({



    mainContainer: {


      marginLeft: '20px',
      marginRight: '20px',
      marginTop: '70px',
      [theme.breakpoints.up('md')]: {
        marginLeft: '200px',
        marginRight: '200px',
        marginTop: '150px'
      }

    },




    sectionEvent: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row'

      }

    },

    eventMainTitle: {
      fontSize: '2.5rem',
      fontWeight: 600,
      lineHeight: '3rem',

      [theme.breakpoints.up('sm')]: {
        fontSize: props => props.isTitleShort ? '4.5rem' : '2.5rem',
        lineHeight: props => props.isTitleShort ? '4.3rem' : '2.9rem'
      }
    },


    avatarContainer: {
      width: "60px",
      height: "60px"
    },
    subEventContainer: {
      marginTop: "30px",
      display: "flex",
      width: '100%',
      flexDirection: "column",
      [theme.breakpoints.up('sm')]: {
        marginTop: "80px"
      }

    },
    avatarText: {
      fontSize: '0.7rem',
      fontWeight: 'bold',
      lineHeight: '0.8rem'
    },

    detailsTitle: {
      fontSize: '1.1rem',
      textDecoration: 'underline',


    },
    detailsContainer:{
      flexDirection:"column",
  
     
      [theme.breakpoints.up('sm')]: {
        display:"flex",
        flexDirection:"row",
        marginTop:"20px",flexWrap:
        "wrap"
      }

    },
    mainImage: {
        width: '100%',
        height: '0px',
        paddingTop: '75%',
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
          marginTop: "0px"
        }
    },
    detailsText: {
      fontSize: '1.1rem',
      marginLeft: '10px',
      marginRight: '30px'
    },
    ttc: {
      fontSize: '0.7rem',
      fontWeight: 'bold',
      position: "relative",
      top: '1.2rem'
    }
  })
})

export default useStyles

// export const BlackSubscription = styled(Button)({
//   root: {
//     backgroundColor: red[600],
//     width: '150px',
//     color: 'white',
//     height: '40px',
//     borderRadius: '30px',
//     fontSize: '0.6rem',

//     '&:hover': {
//       backgroundColor:red[800]
//     }
//   },
//   label: {
//     textTransform: 'none',
//   },
// })

export const BlackSubscription = styled((Button))({
 
  backgroundColor: red[600],
  width: '150px',
  color: 'white',
  height: '40px',
  borderRadius: '30px',
  fontSize: '0.6rem',

  '&:hover': {
    backgroundColor: red[800]
  }

})
