import React, {  useState, useEffect, useRef } from "react"
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'

import useStyles,  {sxStyle} from '../events/sectionProgramStyle'
//import useStyles from '../events/sectionProgramStyle'
import FrontOfficeTheme from '../../../style/globalTheme'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import Carousel from '../../../components/caroussels/carousel'
import { indigo } from '@mui/material/colors';
import VignetteHomepageCycle from '../../../components/caroussels/vignetteHomepageCycle'
import WebinarIcon from '../../../assets/webinar.svg'
import cleanHtml from '../../../components/SVGIcons/helpers'
import {ButtonHandler} from '../general/showLess'



const maxWordSize = 80
const smallTextLimit = 800


const SectionEvents = ({ data }) => {

   


    const classes = useStyles()

    const [isMounted, setIsMounted] = useState(false)
    const [hideButton, setHideButton] = React.useState(false)
    const [toggle, setToggle] = React.useState(false)
    const matches = useMediaQuery(theme => theme.breakpoints.down('md'))
    const divRef = useRef(null)
    const pageCycle = data.pageCycle ? data.pageCycle : data.originalPageCycle

    const props = {
        cutHtml : toggle
    }
    const htmlClass = (pageCycle?.documentHtml?.length > smallTextLimit) ? sxStyle(props).htmlContainer : sxStyle(props).htmlContainerShort

 

    useEffect(() => {

        if (pageCycle.documentHtml && pageCycle.documentHtml.length <  maxWordSize){
            setHideButton(true)
        }
        if ( !pageCycle.documentHtml)
        setHideButton(true)


        if (!isMounted)
            setIsMounted(true)

    }, [])

    const events = pageCycle.children.filter(child => child.title1)
        .sort((a, b) => a.date - b.date)

    
    

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={FrontOfficeTheme}>
                
                <Carousel isMounted={isMounted} cellTemplate={VignetteHomepageCycle} cellsData={events} carouselConfig={carouselConfigWebinar} />
                <Box sx={htmlClass} ref={divRef}>
                    
                    <div sx={sxStyle().divHtmlContainer}  dangerouslySetInnerHTML={{ __html: cleanHtml(data?.pageCycle?.documentHtml) }} />
                </Box>
                {matches && !hideButton && <ButtonHandler toggle={toggle} setToggle={setToggle} />}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}


export default SectionEvents

const carouselConfigWebinar = {
    title: "Le programme",
    titleMarginBottom: '60px',
    titleBorderBottom: '1px solid black',
    carouselHeight: 300 ,
    dots: true,
    cellRatio: 1,
    icon: WebinarIcon,
    tippyContent: <Box padding="10px" >


        <p style={{ lineHeight: '1.1rem' }}>
            Les webinars ou webinairs: ll s’agit de conférences dont <span style={{ color: indigo[600], fontWeight: 'bold' }}> la capacité est supérieure à 50 participants.</span>

        </p>
        <p style={{ lineHeight: '1.1rem' }}>
            Elles ont pour objectif de partager l’intervention d’un expert en direct avec un nombre plus élevé de participants qui pourront poser des questions à l’aide de l’outil de chat voire en étant invité par l’organisateur à « monter sur scène » pour intervenir ou poser une question.

        </p>
    </Box>,
    breakpoints: {
        50: {
            slidesPerView: 1.1,
            slidesPerGroup: 1,
            spaceBetween: 10
        },
            
        637.5: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 10
        },
        // when window width is >= 480px
        1220: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 10
        },
        // when window width is >= 640px
        1500: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 10
        }
    }
}



