import React, {  useEffect, useState, useRef } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'



import randomColor from 'randomcolor'


import useStyles from '../../components/style/pages/eventsSection'
import { navigate } from 'gatsby'
import getDates from '../../helpers/dates'
import { SwiperSlide } from 'swiper/react'

import { grey } from '@mui/material/colors';


const VignetteHomepageCycle = ({ data: event, position, index, parentIsMounted, totalNb }) => {




    const getFormatted = (duree) => {
        if (duree === 60)
            return '1h'
        if (duree === 120)
            return '2h'
        if (duree === 90)
            return '1h30'
        else
            return duree + 'mn'
    }


   

    const classes = useStyles()
    const boxRef = useRef(null)
    const [isMounted, setIsMounted] = useState(false)
    const [isImageLoaded, setIsImageLoaded] = useState(false)

    const children = event.children

    const largeImageUrl = children?.find(image => (image.original && image.original.src.includes('L')))?.original.src
    const ETagImage500 = event.imageETag500 ? `https://www.pekoia.com/static/L${event.imageKey}-500-${event.imageETag500}.jpg` : null
    
    const finalUrl = ETagImage500  || largeImageUrl

    useEffect(() => {

        if (!isMounted)
            setIsMounted(true)


        if ((position > index - 7) && !isImageLoaded && finalUrl){
            const img = new Image()
            img.onload = () => {
                setIsImageLoaded(true)
                

            }
            img.src = finalUrl

        }





    }, [position])

  



    if (!event.children && !event.imageETag500)
        return null

    const color = randomColor({ luminosity: 'light', hue: 'blue' })




    if (!largeImageUrl && !ETagImage500)
        return null

    const imageUrl = ETagImage500  || largeImageUrl





    return (<SwiperSlide><Box width='calc(100% - 20px)' height='100%' display="flex" flexDirection="column" >



        <Box width='100%' height='100%' style={{ cursor: 'pointer' }} onClick={() => { 
            
            event?.fields?.slug && navigate(event.fields.slug) }}>
            <Box width="100%" height="55%" ref={boxRef}>
                {parentIsMounted && isImageLoaded && <img  className={classes.webinarImage} src={finalUrl} alt={event.title1}/>}
                {parentIsMounted && !isImageLoaded &&  <Box height="100%"color={color} bgcolor={color} display="flex" alignContent="center" alignItems="center">@</Box>}
              

            </Box>
            <Box marginTop="10px">
                <Typography style={{ color: 'black', fontSize: '0.7rem', fontWeight: 500, lineHeight: '0.8rem' }}>
                    {index+1}/{totalNb}{" "}-{" "}
                    {event.title1 && event.title1.length > 45 ? event.title1.slice(0,45)+'...': event.title1}
                </Typography>
                <Box marginTop="2px">


                    <Typography style={{ color: grey[600], fontSize: '0.7rem', fontWeight: 500, lineHeight: '0.8rem' }}>

                        {event.title2}
                    </Typography>
                </Box>
                <Box marginTop="5px">
                    <Typography style={{ fontSize: '0.6rem' }}>
                        {getDates(event.date).dateShort.split('/').slice(0, 2).join('/')}{' à '}{getDates(event.date).time}h {event.duree && " (durée "+getFormatted(event.duree)+")"}
                    </Typography>
                   

                </Box>
               

            </Box>

        </Box>

    </Box>
    </SwiperSlide>)
}

export default VignetteHomepageCycle




















