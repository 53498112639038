import React, { useContext, useState, useEffect } from 'react'
import { graphql } from 'gatsby'

import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import useStyles, { sxStyle } from './components/events/eventStyle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { convert } from 'html-to-text'
import Seo from "../components/seo"


import FrontOfficeTheme from '../style/globalTheme'
import Layout from "../components/layout"
import SubscriptionModal from '../workflows/subscriptionModal/main'
import { AuthContext } from '../context/general'

import SectionCycle from './components/cycles/sectionCycle'
import SectionEvents from './components/cycles/sectionEvents'
import { DEBUG } from '../buildSpecs'


export const Head = ({ location, params, data, pageContext }) => {
  
    const pageCycle = data.pageCycle ? data.pageCycle : data.originalPageCycle
    const slug = pageCycle.fields.slug
    const pageDescription = convert(pageCycle?.documentHtml, {
      wordwrap: 130
    })
  
    const imageSlug = `/static/L${pageCycle.imageKey}-800-${pageCycle.imageETag800}.jpg`
  
    return (
      <Seo title={pageCycle.title} slug={slug} description={pageDescription} image={imageSlug} />
            
      )
  
  }
const PageCycle = ({ data }) => {
  const matches = useMediaQuery(theme => theme.breakpoints.up('md'));

  const classes = useStyles()
  const { authState } = useContext(AuthContext)
  const [isMounted, setIsMounted] = useState(false)

  // KEY-HANDLER USED TO SHOW INFORMATION

  const [keySequence, setKeySequence] = useState('')
  const [showInfo, setShowInfo] = useState(false)

  const handleKeyPress = (event) => {
    let newKeySequence = null
    if (keySequence.length < 7)
      newKeySequence = keySequence + event.key


    else
      newKeySequence = keySequence.substring(1, 7) + event.key

    setKeySequence(newKeySequence)

    if ((newKeySequence) == 'pekoia!')
      setShowInfo(true)
  }

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  // END OF KEY-HANDLER USED TO SHOW INFORMATION





  useEffect(() => {

    setIsMounted(true)

  }, [])


  if (DEBUG) {

    if (DEBUG == 1)
      return (<Box>DEBUG MODE</Box>)


  }



  if (!isMounted)
    return null




  if (!data)
    return null



  const pageCycle = data.pageCycle ? data.pageCycle : data.originalPageCycle


  const pageDescription = convert(pageCycle?.documentHtml, {
    wordwrap: 130
  })

  const slug = pageCycle.fields.slug
  // const imageSlug = data.pageCycle.children.find(child => child.ogImage).ogImage.images.fallback.src


  const children = pageCycle.children
    .filter(child => child.gatsbyImageData)
    .filter(child => !child.original.src.includes('800'))
    .filter(child => !child.original.src.includes('500'))
    .filter(child => !child.original.src.includes('300'))
    .filter(child => !child.original.src.includes('blur'))

  let gatsbyImageData = null
  if (pageCycle.children.length) { }
  gatsbyImageData = children.find(child => (child.gatsbyImageData && child.original.src.includes('static/L')))?.gatsbyImageData

  const imageSlug = `/static/L${pageCycle.imageKey}-800-${pageCycle.imageETag800}.jpg`


  //const imageSlug = imageSlugRaw[0] === '/' ? imageSlugRaw.slice(1, imageSlugRaw.length) : imageSlugRaw


  // console.log('@SEO')
  // console.log(slug)
  // console.log(pageDescription)
  // console.log(imageSlug)


  if (authState.showSubscriptionModal) {

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={FrontOfficeTheme}>

          <Layout data={data}>
            <SubscriptionModal />
          </Layout>
        </ThemeProvider>
      </StyledEngineProvider>
    );

  }

  return (
    <Layout data={data}>
      {data.pageCycle && <Fade in={showInfo}><Box padding='30px'>{data.pageCycle.fields.cycleId}</Box></Fade>}

      <ThemeProvider theme={FrontOfficeTheme}>

        <Box sx={sxStyle.mainContainer}>
          <SectionCycle data={data} />
          <SectionEvents data={data} />


        </Box>

      </ThemeProvider>
    </Layout>

  );
}

export default PageCycle

export const pageQuery = graphql`
        query ($slug: String!){
            site {
              siteMetadata {
                url
              }
            }
            mainImage: imageSharp(original: {src: {regex: "/webinar/"}}) {
              gatsbyImageData
            }
            pageCycle: dbCycle(
              fields: {slug: {eq: $slug}}
            ) {
              title
              documentHtml
              prix
              imageKey
              imageETag300
              imageETag800
              replayStatus
              fields {
                cycleId
                slug
              }
              children {
                ... on DBEvent {
                  title1
                  title2
                  date
                  fields {
                    slug
                  }
                  id
                  imageKey
                  imageETag500
                  cycleId
                  duree
                }
                ... on DBFormateur {
                  id
                  nom
                  prenom
                  lemonwayId
                  sanboxLemonwayId
                  hashList
                  intro
                  fields {
                    formateurId
                    slug
                  }
                  children {
                    ... on ImageSharp {
                      gatsbyImageData
                      original {
                        src
                      }
                    }
                  }
                }
                ... on ImageSharp {
                  gatsbyImageData(quality: 100, width: 1000)
                  original {
                    src
                  }
                  thumbnail: gatsbyImageData(width: 150, quality: 100)
                  ogImage: gatsbyImageData(
                    width: 1000
                    height: 630
                    quality: 80
                    placeholder: BLURRED
                    transformOptions: {cropFocus: ATTENTION}
                  )
                }
              }
            }
            originalPageCycle: dbCycle(
                fields: {originalSlug: {eq: $slug}}
              ) {
                title
                documentHtml
                prix
                imageKey
                imageETag300
                imageETag800
                replayStatus
                fields {
                  cycleId
                  slug
                }
                children {
                  ... on DBEvent {
                    title1
                    title2
                    date
                    fields {
                      slug
                    }
                    id
                    imageKey
                    imageETag500
                    cycleId
                    duree
                  }
                  ... on DBFormateur {
                    id
                    nom
                    prenom
                    lemonwayId
                    sanboxLemonwayId
                    hashList
                    intro
                    fields {
                      formateurId
                      slug
                    }
                    children {
                      ... on ImageSharp {
                        gatsbyImageData
                        original {
                          src
                        }
                      }
                    }
                  }
                  ... on ImageSharp {
                    gatsbyImageData(quality: 100, width: 1000)
                    original {
                      src
                    }
                    thumbnail: gatsbyImageData(width: 150, quality: 100)
                    ogImage: gatsbyImageData(
                      width: 1000
                      height: 630
                      quality: 80
                      placeholder: BLURRED
                      transformOptions: {cropFocus: ATTENTION}
                    )
                  }
                }
              }
          }
          
        `